import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    search: "search",
    inactive: "Show Inactive",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    cancel: "Cancel",
    return: "Return",
    days: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    },
    serverDown: {
      ligne0: "Connecting to Perfcell server...",
      ligne1: "ONE MOMENT PLEASE",
      ligne2: "validation of the connection",
      ligne3: "with the server",
    },
    sameLine: "There is another device being setup on same line!",
    existInActivities: "Cannot be deleted because used in a current or past production! Go back to View/Edit and remove the arrow from the Active field.",
    login: {
      title: "Please sign in",
      name: "Client name",
      namePHolder: "Name",
      key: "Private key",
      keyPHolder: "Key",
      login: "Sign in",
      error: "Login failed!",
    },
    admin: {
      title: "Please enter administrator password",
      placeholder: "Password",
      login: "Enter admin area",
      error: "Wrong password!",
    },
    config: {
      title: "Configuration",
      byOID: "By Order ID",
      BI: "Business Intelligence",
      Dashboard: "Efficiency & Quality Dashboard",
      Dashboard2: "Attendance Dashboard",
      Reports: "Reports",
      Company: "Company",
      Categories: "Categories",
      Departments: "Departments",
      Downtimes: "Downtimes",
      Changeovers: "Changeovers",
      Employees: "Employees",
      Incentive: "Incentive",
      Lines: "Lines, Cells, Machines",
      MessageRec: "Message Recipients",
      Schedules: "Schedules",
      SetupTimes: "Setup & Quality Assurance",
      Standards: "Standards",
      Tasks: "Tasks",
      Defects: "Defects",
      TextMessages: "Text Messages",
      Andon: "Andon",
      Message: "Send Messages to Lines",
      CleaningFactor: "Cleaning factors",
    },
    footer: {
      oper: "Operations",
      config: "Configuration",
      dashboard: "Efficiency & Quality Dashboard",
      dashboard2: "Attendance Dashboard",
      inboundDash: "Inbound Dashboard",
      reports: "Reports",
      BI: "Business Intelligence",
      logout: "Logout from configuration",
      quit: "Quit Perfcell",
      confirm: "Do you really want to quit the Perfcell Application ?",
      date: "dddd, MMM D YYYY",
      timezone: "Select a timezone in config",
      perfcellId: "Perfcell Id",
      inspect1: "Please take the next mask and label it with the purple cardboard",
      inspect2: "as a lab test sample.",
      inspect3: "Be sure to write down the cell, date and sample #.",
    },
    reports: {
      searchID: "Enter the Order ID",
      byLine: "By Line",
      byO_journey: "By Order´s Journey",
      singgle_order_journey: "Single order ID journey",
      waitingNotice: "For more than 2 days, report might take a couple of minutes to be generated.",
      byOID: "By Order ID",
      byOID_XLSX: "By Order ID EXCEL",
      byOID_PDF: "By Order ID PDF",
      uncompleteOrder: "Uncompleted Orders Only",
      title: "Reports",
      cancel: "Return",
      submit: "Generate",
      from: "FROM",
      to: "TO",
      orderId: "ID",
      daily: "Daily report",
      xlsDaily: "Standard Excel daily",
      pdfDailyStd: "Standard PDF daily",
      pdfDailyOEE: "OEE PDF daily",
      xlsDailyOEE: "OEE Excel daily",
      comment2: "Report for regular lines and cells.",
      comment3: "Report for Automation.",
      downtime: "Downtime report",
      emplist: "Employees list",
      emp: "Employees report",
      empDaily: "CSV daily",
      empDetail: "PDF detailed",
      products: "Products report",
      eff: "Efficiency",
      prod: "Production",
      quality: "Quality Performance",
      review: "Review",
      qemp: "Employee report",
      fromWeek: "FROM WEEK",
      toWeek: "TO WEEK",
      qEmps: "EMPLOYEE(S)",
      ship: "Shipping report",
      shipQC: "Use QC report",
      std: "Standards report",
      sys: "System report",
      before: "To Date must be greater or equal to From Date",
      nbDays: " days maximum",
      linePHolder: "Select line (s)",
      schedPHolder: "Select schedule (s)",
      stdCatPHolder: "Select standard category (ies)",
      stdPHolder: "Select standard (s)",
      empPHolder: "Select employee (s)",
      weekPHolder: "Select week",
      errQC: "Error reading QC report",
      toBigQC: "QC report is to big. Maximum 1 GB.",
      noLine: "Select at least one line.",
      weekDate: "MMM D",
      week: "Week ",
      fromWFD: ", from ",
      toWLD: " to ",
      splitLines: "Split lines.",
      withInactive: "Include inactives",
      addPageJump0: "No page jump",
      addPageJump1: "Add page jumps per Line",
      addPageJump2: "Add page jumps per Line and per Schedule",
      newFileQC: "Upload a new QC report.",
    },
    factory: {
      title: "Factory Details",
      cancel: "Return",
      submit: "Save Changes",
      name: "Factory Name:",
      namePHolder: "Enter name",
      logo: "Logo file (.png only):",
      error: "Please correct the following error: | Please correct the following errors:",
      png: "Logo should be a .png file!",
      success: "Successfully saved",
      version: "Version",
      psw: "Administrator Password",
      adminTimeout: "Administrator password timeout ",
      inSeconds: "(in seconds)",
      timeZone: "Time Zone",
      tzPHolder: "Please select a Time Zone",
      pswLength: "Password must be 5 characters minimum",
      timeZoneErr: "You need to select a Time Zone",
      dateFormat: "Dates format",
    },
    factorySetup: {
      errorMsg: "The picture must have a maximum of 1.0 MB",
      browse: "Browse",
      title: "Setup & Quality Assurance",
      setup: "Setup",
      startDay: "Start of day buffer ",
      decimals: "Decimals on Actual",
      minEarned: "Minutes earned on standard change",
      inMinutes15: "(in minutes, maximum 90 minutes)",
      inMinutes90: "(in minutes, maximum 90 minutes)",
      cancel: "Return",
      submit: "Save Changes",
      success: "Successfully saved",
      error: "Please correct the following error: | Please correct the following errors:",
      breaksInc: "Include breaks time in worked time",
      scanned: "Allow same scanned WO No. on different Lines",
      needTask: "Employees must choose a task in the operation",
      custom: "Custom minutes earned buttons",
      max6: "Maximum 6 custom minute",
      new: "Add new custom minute",
      delete: "Delete this custom minute",
      confirmDelete: "Are you sure you want to completely remove this custom minute",
      desc: "Description",
      minutes: "Minutes",
      icon: "Icon(only PNG)",
      quality: "Quality Checks",
      inspector: "Inspector",
      inspPHolder: "Select the inspector...",
      lines: "Line(s)",
      linesPHolder: "Select line(s) to inspect...",
      sched: "Schedule(s)",
      schedPHolder: "Select schedule(s) for inspection...",
      fixedQuality: " Fixed :",
      afterEvery: " After every",
      nbFixedQuality: " units produced",
      randomQuality: " Random :",
      nbRandomQuality: " inspections per schedule",
      msgInspectorQuality: " Message to the inspector",
      msgTabletQuality: " Message on the tablet",
      zeroNbFixed: "Units produced must be one or more.",
      zeroNbRandom: "Inspections per schedule must be one or more.",
      noDestination: "Select at least one Message destination.",
      noInspector: "Inspector is missing.",
      noLine: "Line(s) is missing.",
      noSchedule: "Schedule(s) is missing.",
      maxDecimals: "Maximum number of decimals is 2",
      maxBuffer: "Maximum Buffer is 90 minutes",
      moveToLine: "Moving employees from one line to another option in the Operation module",
    },
    incentive: {
      title: "Incentive",
      smallInPercent: " (in percent)",
      smallHourlyRate: " (Hourly Rate)",
      hourlyRate: "Hourly Rate ",
      minimumPercent: "Minimum for Bonus ",
      baseBonus: "Base Bonus after reaching the minimum efficiency ",
      maximumBonus: "Maximum Bonus ",
      efficiencyNoTime: "Efficiency for product with no standard time ",
      cancel: "Return",
      submit: "Save Changes",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
    },
    configScList: {
      title: "Schedule Management",
      search: "Search in Description",
      desc: "Description",
      start: "Start Time",
      end: "End Time",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Schedule ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Schedule",
    },
    configSched: {
      title: "Schedule Detail",
      desc: "Schedule Description",
      descPHolder: "Enter schedule description",
      weekdays: "Weekdays for schedule start",
      info: "Selected days are ",
      green: "Green",
      start: "Start Time",
      end: "End Time",
      lunch: "Lunch Time",
      break1: "Break Time 1",
      break2: "Break Time 2",
      cancel: "Return",
      submit: "Save Changes",
      descLength: "Description must be at least 3 characters long",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
      active: "Active",
      noStartTime: "Start time is missing",
      noEndTime: "End time is missing",
      noWeekDay: "You should select at least one Day",
      missLunch: "You need to set Lunch Length in configuration before adding Lunch Time",
      missBreak: "You need to set Break Length in configuration before adding Break Time",
      badTimes: "Lunch and Breaks shall not overlap or extends beyond Schedule",
      overlap: "This schedule overlap two days. In report put data in:",
      startDay: "the day the schedule start",
      endDay: "the day the schedule end",
      split: "both days",
      realStart: "using the real start time",
      configStart: "using the configured start time",
      realEnd: "using the real end time",
      configEnd: "using the configured end time",
      autoLoggin: "To enable employees auto-login feature, select one of the following options:",
      beginShift: "the employees logged at the beginning of the shift will be logged the next scheduled day",
      endShift: "the employees logged at the end of the shift will be logged the next scheduled day",
      lunchLength: "Lunch length ",
      inMinutes90: "(in minutes, maximum 90 minutes)",
      maxLunch: "Maximum lunch time is 90 minutes",
      break: "Break ",
      maxBreak: "Maximum break time is 90 minutes",
      breakLength: "Duration of the break",
    },
    configLinesList: {
      title: "Lines, Cells and Machines Management",
      search: "Search in Name or Description",
      name: "Name",
      desc: "Description",
      edit: "View/Edit",
      activeInactive: "Active/Inactive",

      remove: "Remove",
      clone: "Clone",
      confirm: "Delete this Line ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Line",
    },
    configLine: {
      automaticStart: "Automatic Line start​",
      multiBox: "Multi-Box",
      title: "Line Detail",
      name: "Line Name",
      namePHolder: "Enter line name",
      selectSchedule: "Select Schedule (s)",

      desc: "Line Description",
      descPHolder: "Enter line description",
      supportLine2: "Support Line 2",
      associateLines2: "If Support Line 2 is checked, the efficiency of this line and operators will be based on these Lines:",
      lines2PHolder: "Choose all support LINES",
      extScanner: "Line works only with scanner.",
      scannerLines: "External scans from following lines will feed current line.",
      scannerPHolder: "Choose all LINES",
      batch: "Line Batch Size",
      supApproval: "Need supervisor approval to close the session before the end of the schedule",
      inspectLine: "This is an Inspection Line",
      tabNoEff: "No efficiency display on the Operation module",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      nameLength: "Name must be at least 2 characters long",
      lineExist: "The Line Name already exist",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
      needTasks: "Employees must choose a task in the operation",
      notAllSched: "Only current schedule is considered for efficiency’s calculation",
      allSched: "Include all schedules in efficiency calculation",
      highEff: "When efficiency will not vary with the # of operators: Automation, Legacy lines, Calander",
      cOverBegin: "Popup question after Starting the line: ‘Are you on changeover or production’",
      assignTasks: "Apply all tasks to this line?",
      oui: "Yes",
      non: "No",
      askConfirm: "Are you sure to confirm these changes",
      selectCleaningFactor: "Select a cleaning factor",
      minutes_added_standard_change: "Minutes Given to the team (Added to the Earned Minutes) for standard change",
    },
    configTasksList: {
      title: "Tasks Management",
      search: "Search in Name or Description",
      name: "Name",
      desc: "Description",
      edit: "View/Edit",
      remove: "Remove",
      clone: "Clone",
      confirm: "Delete this Task ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Task",
    },
    configTask: {
      title: "Task Detail",
      name: "Task Name",
      namePHolder: "Enter task name",
      desc: "Task Description",
      descPHolder: "Enter task description",
      lines: "This Task can be used on these Lines:",
      isSupport1: "This option can used for:",
      isSupport2: "1- Support staff (supervisors, replenisher) who services more than 1 line",
      isSupport3: "2- Task that is shared between more than 1 line",
      isSupport4: "In both cases, we loose the efficiency exactitude but have a track on quality",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      nameLength: "Name must be at least 3 characters long",
      lineExist: "The Task Name already exist",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
    },
    configAndonsList: {
      title: "Andons Management",
      search: "Search in Id or Description",
      nodeId: "Node ID",
      desc: "Description",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Andon ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Andon",
    },
    configAndon: {
      title: "Andon Detail",
      nodeId: "Andon Node ID",
      nodeIdPHolder: "Enter Andon Node ID",
      desc: "Andon Description",
      descPHolder: "Enter Andon description",
      associateLines: "Associated Lines",
      linesPHolder: "Choose all lines",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      andonExist: "The Andon ID already exist",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
    },
    tabLine: {
      noLine: "Step 1 : Click to select a Line",
      emptySched: "Schedule",
      noSched: "Click to select a Schedule",
      schedLock: "You cannot change schedule while in production. Close and restart the session if necessary",
    },
    tabStd: {
      emptyStd: "Part Number",
      noStd: "Step 2 : Click to choose a Standard Time that you will be working on (Category, Part#, Operation)",
      SAM: "SAM ",
      SCT: "SCT ",
      NAT: "NAT ",
      Takt: "Takt Time : ",
      CD: "CD : ",
      units: "Units",
    },
    tabTeam: {
      btn_remove: "Move or delete an employee",
      btn_edit: "Change the employee's task",
      emptyTeam: "Team Members",
      noTeam: "Step 3 : Click to select Team Members",
      empsWorking: "These employees are already on another line and were removed from this line : ",
      empWorking: "This employee is already on another line and was removed from this line : ",
      add: "CHANGE ",
      actual: "Actual : ",
      members: "Team Member | Team Members",
      remove: "remove",
      cancel: "cancel",
      last: "You can not remove last employee on line.",
      editTeam: "change Team",
      moveToLine: "Do you wish to remove that person permanently or move to another line?",
      moveIt: "move to Line",
      deleteIt: "remove",
    },
    tabControl: {
      minutes: " minutes added",
      title4: "Downtime",
      comment40: "Machine broken",
      comment41: "Break and lunch",
      comment42: "Swab",
      comment43: "Meeting",
      title5: "Changeover time",
      comment50: "Beginning of the day setup",
      comment51: "Change product or quantity",
      comment52: "Cleanup",
      prepOrProd1: "Welcome to the ",
      prepOrProd2: ", are you preparing (set-up the machine, getting the bags, products…) or ready to produce?",
      preparing: "Preparing",
      produce: "Produce",
    },
    selectLine: {
      noLine: "There is no Line configured",
      sLine: "Select a line",
      name: "Name",
      desc: "Description",
      cancel: "Cancel",
      save: "Save",
    },
    selectSendToLine: {
      noLine: "No other line in operation",
      selectTask: "Select a Task",
    },
    selectSched: {
      noSchedule: "There is no Schedule configured",
      sSchedule: "Schedule",
      desc: "Description",
      cancel: "Cancel",
      save: "Save",
    },
    selectStd: {
      noStandard: "There is no Standard configured",
      noCat: "There is no Category configured",
      allCatStds: "ALL STANDARDS",
      catName: "Categories names",
      selectCat: "Select a category",
      sStandard: "Select a standard",
      stdPart: "Part Number",
      stdDesc: "Description",
      category: "Category",
      searchPHolder: "Type to Search",
      clear: "Clear",
      useTakt: "Use the Takt Time",
      valueNAT: "Net Available Time (NAT) : ",
      valueCD: "Customer Demand (CD) : ",
      changeCat: "Change Standard Category",
      cancel: "Cancel",
      save: "Save",
      courierProcessType: "Carrier process type:",
      courierId: "Is the carrier id:",
      routeNumber: "Is the route number:",
      confirm: "Confirm",
      isCourierProcess: "¿Is carrier process?:",
      inboundDescription: "The program takes the *carrier id* of the first codebar and validates that the subsequent packages belong to the same courier",
      outboundDescription: "The program takes the *route number* of the first codebar and validates that the subsequent packages belong to the same route",
      inboundTitle: "Is the inbound carrier",
      outboundTitle: "Carrier outbound process validation",
      courierProcessTypeSelect: "Carrier process type:",
      yes: "Yes",
      no: "No",
    },
    selectDown: {
      noDown1: "There is no Downtime available for this line.",
      noDown2: "Go to the Downtime Module and add this line.",
      sDown: "Select a downtime reason",
      texte: "Description",
      password: "Password",
      enterPsw: "Supervisor should enter password",
      wrongPsw: "Wrong password",
      cancel: "Cancel",
      save: "Start Complete Downtime",
    },
    selectChangeover: {
      noChangeover1: "There is no Changeover available for this line.",
      noChangeover2: "Go to the Changeover Module and add this line.",
      sChangeover: "Select a changeover reason",
      texte: "Description",
      password: "Password",
      enterPsw: "Supervisor should enter password",
      wrongPsw: "Wrong password",
      cancel: "Cancel",
      save: "Start Changeover",
    },
    selectSms: {
      fromList: "Choose Message text",
      typeText: "Or enter a text",
      noRecip: "There is no Recipient configured",
      sRecip: "Select a Recipient",
      noMsg: "There is no Message configured",
      sMsg: "Select a Message",
      inputMsg: "Message text",
      enterText: "Enter text",
      cancel: "Cancel",
      save: "Send",
    },
    selectDefect: {
      noDefect: "There is no defect configured",
      sDefect: "Select a defect",
      inputComment: "Comment",
      enterText: "Enter text",
      cancel: "Cancel",
      save: "Save",
    },
    selectHelp: {
      machine: "Broken Machine",
      besoin: "Need furnitures or raw material",
      question: "Question for supervisor or quality insurance",
      red: "Clear Red Andon",
      orange: "Clear Yellow Andon",
      green: "Clear Green Andon",
      message: "Do you want to send a text message?",
      total: "Is your line completely stopped?",
      oui: "Yes",
      non: "No",
      cancel: "Cancel",
      save: "Select",
    },
    endProd: {
      confirmOut1: "You are ",
      confirmOut2: " minutes ahead of end of schedule",
      confirmOut3: "You need supervisor approval to end the session",
      password: "Password",
      enterPsw: "Supervisor should enter password",
      wrongPsw: "Wrong password",
      cancel: "Cancel",
      save: "End Production",
    },
    selectTeam: {
      noEmployee: "There is no Employee configured",
      title: "Select all Employees in Team",
      newTeam: "Employees in Team : ",
      addEmp: "These Employees will be added : ",
      removeEmp: "These Employees will be removed : ",
      firstName: "First Name",
      lastName: "Last Name",
      alias: "Employee #",
      task: "Task",
      selectTask: "Select a Task for ",
      taskPHolder: "Select a Task",
      onLine: "Line",
      search: "Search",
      next: "next",
      notFound: "Text not found",
      clearList: "Clear All",
      cancel: "Cancel",
      save: "Save",
      enterTask: "- TASK IS MISSING -",
      grey: "This employee is already in another line",
    },
    standardReport: {
      entry: "Standard",
      status: "Status",
      fermer: "close",
      updated: "Updated",
      created: "Created",
      categoryError: "Error: category not exist",
    },
    configStdsList: {
      title: "Standards Management",
      search: "Search in Part Number or Description",
      part: "Part Number",
      desc: "Description",
      sam: "Standard Time",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Standard ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Standard",
      confirmStatus: "Are you sure you want to make changes in standard management",
      import: "Import csv",
    },
    configStd: {
      linesHeading: "This standard can be used on these Lines:",
      linesPH: "Select Lines",
      title: "Standard Detail",
      partNumber: "Part Number",
      partPHolder: "Enter part number",
      desc: "Standard Description",
      descPHolder: "Enter standard description",
      SAM: "Standard Time : Time allowed to make one product at 100% efficciency",
      effNoTime: "Use pre-established efficiency",
      hour: "Hours",
      min: "Minutes",
      sec: "Seconds",
      category: "Standard Category",
      catPHolder: "Enter this standard Category",
      toProduce: "Quantity to produce (0 for unlimited quantity)",
      noChangesAllowed: "Quantity can only be stored and saved once, no changes is allowed",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      partLength: "Part Number must be at least 3 characters long",
      missingCat: "Category is mandatory",
      bigSAM: "SAM must be less than 24 hours",
      smallSAM: "SAM must be at least .1 second",
      partExist: "The Part Number already exist",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
    },
    configUsersList: {
      title: "Employees Management",
      search: "Search in First Name, Last Name or Employee #",
      firstName: "First Name",
      lastName: "Last Name",
      alias: "Employee #",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this User ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new User",
      importUsers: "Import csv",
      updated: "Employee updated",
      created: "Employee created",
      number: "error: invalid number of fields",
      empty: "error: one or more empty field",
      server: "server error",
    },
    usersReport: {
      entry: "csv file entry",
      status: "Status",
      fermer: "close",
    },
    configUser: {
      title: "Employee Detail",
      first: "First Name",
      firstPHolder: "Enter employee first name",
      last: "Last Name",
      lastPHolder: "Enter employee last name",
      alias: "Employee #",
      aliasPHolder: "Enter employee number",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      userExist: "The Employee Number already exist",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
    },
    configCategoriesList: {
      title: "Standards Categories Management",
      search: "Search in Category Name",
      name: "Name",
      prodType: "Type",
      type0: "Regular",
      type1: "Reject",
      type1GF: "Rework (reruns)",
      type2: "Reject 2",
      type3: "Other",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Category ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Category",
    },
    configCategory: {
      title: "Category Detail",
      name: "Name",
      namePHolder: "Enter category name",
      normal: "Regular",
      rejectGF: "Rework included in total production number",
      rejectOther: "Rejects included in Processed",
      //reject2: "Rejects not included in Processed",
      other: "Others (Test, samples...)",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      categoryExist: "The Category Name already exist",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
      relatedDefects: "Choose rework (defects) related to this category",
      defectsPHolder: "Choose all defects",
    },
    configDownList: {
      title: "Downtimes Management",
      search: "Search in Downtime Text",
      text: "Text",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Downtime ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Downtime",
    },
    configDown: {
      title: "Downtime Detail",
      text: "Downtime reason",
      textPHolder: "Enter downtime text",
      lines: "Associates lines",
      linesPHolder: "Enter associates lines",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
      password: "Password",
      autoMsg: "Initiate messaging system",
    },
    configChangeoverList: {
      title: "Changeover Management",
      search: "Search in Changeover Text",
      text: "Text",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Changeover ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Changeover",
    },
    configChangeover: {
      title: "Changeover Detail",
      text: "Changeover reason",
      textPHolder: "Enter Changeover text",
      lines: "Associates lines",
      linesPHolder: "Enter associates lines",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
      password: "Password",
    },
    configRecipList: {
      title: "Messaging Recipients Management",
      search: "Search by Name or Phone Number",
      name: "Name",
      tel: "Phone Number",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Recipient ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Recipient",
    },
    configRecip: {
      title: "Recipient Detail",
      name: "Name",
      namePHolder: "Enter Recipient Name",
      phone: "Phone Number",
      phonePHolder: "Enter Recipient Phone Number",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      error: "Please correct the following error: | Please correct the following errors:",
      validPhone: "Phone number is not valid",
      success: "Successfully saved",
    },
    configMsgList: {
      title: "Messaging Text Management",
      search: "Search inside Texts",
      text: "Text",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Message ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Message",
    },
    configMsg: {
      title: "Message Detail",
      text: "Message",
      textPHolder: "Enter Message text",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
    },
    configDefectsList: {
      title: "Defects Management",
      search: "Search inside Texts",
      text: "Text",
      edit: "View/Edit",
      remove: "Remove",
      confirm: "Delete this Defect ?",
      previous: "Previous Page",
      next: "Next Page",
      cancel: "Return",
      addNew: "Add new Defect",
    },
    configDefect: {
      title: "Defect Detail",
      text: "Defect",
      textPHolder: "Enter Defect text",
      active: "Active",
      cancel: "Return",
      submit: "Save Changes",
      error: "Please correct the following error: | Please correct the following errors:",
      success: "Successfully saved",
    },
    tabMain: {
      niceDay: "Have a nice day!",
      thankYou: "Thank you",
      notSetUp: "Operation",
      startProd: "Click to start production",
      outsideSchedule: "The selected schedule is over!",
      empsWorking: "These employees are already on another line and were removed from this line : ",
      empWorking: "This employee is already on another line and was removed from this line : ",
      tooEarly: "Schedule is not started yet",
      notInLunch: "You can not add units while in lunch time",
      notInBreak: "You can not add units while in break time",
      scannerOn: "TO ADD A UNIT MANUALLY, SCANNER BUTTON HAVE TO BE DARK GREEN. PRESS THE SCANNER BUTTON TO CHANGE HIS COLOR.",
      scanned: "Already scanned",
      picker: {
        title: "Picker Information",
        body: "This order was picked by",
      },
      notPicked: {
        title: "Warning",
        body: "This order has not been scanned at the picking  ",
      },
      scanOk: "Good scan",
      notSaved: "The scan was not saved!",
      actual: "Actual Units Produced",
      goal: "Units Goal",
      actualAvrg: "Actual Average Units Per Hour",
      goalAvrg: "Units Per Hour Goal",
      eff: "Efficiency",
      last: "Last Entry :",
      lastScanned: "Last scanned :",
      produce: "To Produce",
      done: "done",
      cycle: "Cycle Time",
      today: "Today's Total",
      desc: "Description",
      style: "Style",
      comment6: "For current product",
      comment7: "For current product in real time",
      comment8: "From the start of the current schedule, without considering Downtime or Changeover",
      comment9: "Based on the standard time of the current product",
      notValidInbound: "This box for carrier **ORDER_COURIER** does not belong to carrier **LINE_COURIER** presently inbounded",
      // "This order doesn't belong to the same CARRIER. Order carrier (**ORDER_COURIER**), line carrier (**LINE_COURIER**)",
      notValidOutbound: "This box for route **ORDER_ROUTE** does not belong to route **LINE_ROUTE** presently outbounded",
      // "This order doesn't belong to the same ROUTE. Order route (**ORDER_ROUTE**), line route (**LINE_ROUTE**)",
      validationError: "Validation error",
      errorInvalidation: "Error in validation, try again",
      orderScanned: "Orders scanned",
      orderScannedTooltip: "Count of Orders",
      breakStart: "Start",
      breakEnd: "End",
      breakDuration: "Duration",
      breakRemaining: "Remaining Time",
      breakTitle: "Break time",
      endBreak: "End break now",
      loading: "Loading",
    },
    incorrectCourierValidation: {
      acceptAndChange: "Accept and change to **MATCHID** **MATCHIDKEY**",
      aceptBox: "Accept this box",
      cancel: "Cancel",
    },
    keyPad: {
      valid: "VALID",
      close: "CLOSE",
    },
    startProd: {
      confirm: "Ready to start production?",
      lineName: "Line : ",
      schedDesc: "Schedule : ",
      lunchTime: "Lunch : ",
      breakTime: "Break : ",
      stdPart: "Standard : ",
      sam: "SAM : ",
      teamList: "Team : ",
      startTime: "Operations will start at ",
      timeLimit: " if Start button is clicked before ",
      startNow: " if you click Start button now",
      cancel: "Cancel",
      start: "Start",
    },
    chartsbi: {
      title: "Business Intelligence Charts",
      cancel: "Return",
      d1: "Daily Production",
      d2: "Use this chart to look at the progression :",
      d3: "• of a new team",
      d4: "• of a team making a new product",
      d5: "• of the team after a process change",
      d6: "• based on the number of operators",
      q1: "Quantity Vs Efficiency",
      q2: "Use this chart to look how the quantity produced of the same product influences the productivity.",
      u1: "Units per hour",
      u2: "Use this chart to look how much the team is consistent hour by hour during the day.",
    },
    biCharts: {
      title: "BUSINESS INTELLIGENCE",
      from: "FROM",
      to: "TO",
      date: "DATE",
      selectLine: "CHOOSE LINE",
      linePHolder: "Select line",
      selectSched: "CHOOSE SCHEDULE",
      schedPHolder: "Select schedule",
      selectStd: "CHOOSE PART #",
      stdPHolder: "Select standards",
      generate: "Generate",
      export: "Export",
      before: "To Date must be greater or equal to From Date",
      nbDays: " days maximum",
      noLine: "Choose a line.",
      noSched: "Choose a schedule.",
      noStd: "Choose at least one standard.",
      cancel: "Return",
    },
    biChartsDP: {
      title: "Daily production and Number of employees",
      chartTitle: "Daily production and Number of employees Chart",
      headerPlant: "Plant",
      headerLine: "Line",
      headerSched: "Schedule",
      headerDate: "Date",
      headerTeam: "Employees",
      headerNbTeam: "Av. team M.",
      headerRegular: "Produced",
      headerOther: "Other",
      headerReject: "Reject",
      exportPdf: "Print PDF",
    },
    biChartsQVE: {
      title: "Quantity of the order versus Efficiency",
      chartTitle: "Quantity versus Efficiency Chart",
      headerDate: "Date",
      headerPart: "Part Number",
      headerDesc: "Description",
      headerQte: "Quantity",
      headerEff: "Efficiency",
      headerSAM: "S.A.M",
    },
    biChartsUPH: {
      title: "Units Produced per Hour",
      chartTitle: "Units Produced per Hour Chart",
      headerDate: "Date",
      headerTeam: "Employees",
      headerFrom: "From",
      headerTo: "To",
      headerRegular: "Produced",
      headerOther: "Other",
      headerReject: "Reject",
    },
    dashboard: {
      title: "Efficiency & Quality Dashboard",
      currProd: "CURRENT PRODUCTION",
      dayProd: "ALL DAY PRODUCTION",
      lineAndSchedule: "LINE & SCHEDULE",
      stats: "STATISTICS",
      head: [
        "Line",
        "Schedule",
        "Standard",
        "Operation",
        "Team members",
        "Actual units",
        "Goal units",
        "Efficiency",
        "Average units per hour",
        "Goal units per hour",
        "Completed units",
        "Other units",
        "Reject Rework units",
        "Efficiency",
        "Takt time",
        "Average cycle time",
        "<div id='comment1'>Average units per hour <span style='color:red'> &#128712;</span></div>",
        "Last hour units",
        "Previous shift units per hour",
        "Trend last vs average",
      ],
      title1: "Average units per hour",
      comment1: "Current shift units produced so far divided by the working time since the start of the shift excluding Downtimes and Breaks.",
      lineSelect: "Displayed lines",
      linesPHolder: "Select all lines",
      mmGoal: "Manminute Goal",
      mmGoalPHolder: "Choose Manminute Goal",
      close: "Close",
      inDownTime: "Total Line Downtime",
      inChangeover: "Changeover",
      fullText: "Show full line description",
    },
    dashboard2: {
      title: "Attendance Dashboard",
      lineSelect: "Displayed lines",
      linesPHolder: "Select all lines",
      close: "Close",
    },
    inboundDash: {
      title: "Real Time Inbound DashBoard",
    },
    downtime: {
      title: "DOWN TIME",
    },
    changeover: {
      title: "CHANGEOVER",
    },
    sendMsg: {
      title: "Send Message to Lines or Employees",
      line: "Send Message to Lines",
      linesPHolder: "Choose running Lines",
      emp: "Send Message to Employees",
      usersPHolder: "Choose working Employees",
      all: "Send Message to all devices running Perfcell",
      message: "The Message text",
      enterText: "Enter text",
      toLines: "Message to Lines : ",
      toUsers: "Message to Employees : ",
      toAll: "Message to All",
      cancel: "Cancel",
      send: "Send",
    },
    confirmAutoLogin: {
      titre: "Confirm that this employee is on this line today: | Confirm that these employees are on this line today:",
      confirm: "Confirm",
    },
    followUps: {
      title: "Quality Performance Review",
      week: "Week ",
      from: " from ",
      to: " to ",
      fDate: "MMM D",
      maxPcent: "Objective of maximum % mistakes : ",
      employee: "Employee : ",
      empPHolder: "Select an employee",
      supervisor: "Supervisor : ",
      suggest: "We suggest you to do : ",
      action: "What have you decided to do? : ",
      actionChosed: "Action choosed : ",
      actionPHolder: "Select an action",
      comment: "Comments : ",
      history: "History :",
      weekHist: "Week",
      maxHist: "Max %",
      empHist: "Employee %",
      suggestHist: "Suggested action",
      actionHist: "Action taken",
      commHist: "Comments",
      cancel: "Return",
      saveMaxPcent: "Save Max %",
      saveEmployee: "Save this Employee review",
      needAction: "You need to select an action",
      needSupervisor: "You need to enter a Supervisor",
      generate: "Generate Employees Excel report",
      savedOk: "Data saved",
      scheds: "Review employees for these schedules :",
      schedsPHolder: "Select all schedules :",
      sortByName: "Sort by name",
      sortByPcent: "Sort by % of issues larger to smaller",
      askSaveEmp: "Do you want to save modifications to this employee?",
    },
    cleaningFactorList: {
      title: "Cleaning factor",
      search: "Search in name",
      table: {
        name: "Name",
        edit: "Edit",
        remove: "Remove",
      },
      footer: {
        new: "Add new cleaning factor",
        cancel: "Return",
        nextPage: "Next page",
        previousPage: "Previous page",
      },
    },
    cleaningFactorDetail: {
      title: "Cleaning factor detail",
      form: {
        factorName: "Cleaning factor name",
        factorNamePlaceholder: "Enter cleaning factor name",
        itemName: "Name of item",
        itemNamePlaceholder: "Enter cleaning factor item name",
        linesLabel: "This cleaning factor can be used on these Lines",
        linesPlaceholder: "Select lines",
        cancel: "Return",
        submit: "Save changes",
        changesSaved: "Changes saved successfully",
        cleaningFactorNameCannotBeEmpty: "The name of cleaning factor cannot be empty",
        cleaningFactorItemsCannotBeEmpty: "The name of cleaning factor items cannot be empty",
      },
    },
    quantityProducedModal: {
      orderComplete: "This order is completed, do you wish to add this quantity anyway?",
    },
  },
  fr: {
    search: "chercher",
    inactive: "Afficher les inactifs",
    yes: "Oui",
    no: "Non",
    ok: "Ok",
    cancel: "Annuler",
    return: "Retour",
    days: {
      0: "Dimanche",
      1: "Lundi",
      2: "Mardi",
      3: "Mercredi",
      4: "Jeudi",
      5: "Vendredi",
      6: "Samedi",
    },
    serverDown: {
      ligne0: "Connexion au serveur Perfcell...",
      ligne1: "UN INSTANT S'IL VOUS PLAÎT",
      ligne2: "validation de la connexion",
      ligne3: "avec le serveur",
    },
    sameLine: "Il y a un autre appareil en cours de configuration sur la même ligne!",
    existInActivities: "Ne peut pas être supprimé car utilisé dans une production actuelle ou passée! Retournez à Voir/Éditer et enlevez la flèche du champs Actif.",
    login: {
      title: "Veuillez vous enregistrer",
      name: "Nom du client",
      namePHolder: "Nom",
      key: "Clef privée",
      keyPHolder: "Clef",
      login: "Se connecter",
      error: "Échec de connexion!",
    },
    admin: {
      title: "Veuillez entrer le mot de passe Administrateur",
      placeholder: "Mot de passe",
      login: "Accéder à la configuration",
      error: "Mauvais mot de passe!",
    },
    config: {
      byO_journey: "By Order's Journey",
      byOID: "Par ID de commande",
      title: "Configuration",
      BI: "Intelligence d'Entreprise",
      Dashboard: "Tableau de Bord d'Efficacité & Qualité",
      Dashboard2: "Tableau de Bord des Présences",
      Reports: "Rapports",
      Company: "Compagnie",
      Categories: "Catégories",
      Departments: "Départements",
      Downtimes: "Arrêts",
      Changeovers: "Mise en course (setup)",
      Employees: "Employés",
      Incentive: "Motivation",
      Lines: "Lignes, Cellules, Machines",
      MessageRec: "Message Destinataires",
      Schedules: "Cédules",
      SetupTimes: "Réglages & Assurance Qualité",
      Standards: "Standards",
      Tasks: "Tâches",
      Defects: "Défauts",
      TextMessages: "Messages Texte",
      Andon: "Andon",
      Message: "Envoyer des Messages aux Lignes",
      CleaningFactor: "Facteurs de nettoyage",
    },
    footer: {
      oper: "Opérations",
      inboundDash: "Tableau de bord entrant",
      config: "Configuration",
      dashboard: "Tableau de Bord d'Efficacité & Qualité",
      dashboard2: "Tableau de Bord des Présences",
      reports: "Rapports",
      BI: "Intelligence d'Entreprise",
      logout: "Quitter configuration",
      quit: "Quitter Perfcell",
      confirm: "Voulez-vous vraiment quitter l'Application Perfcell ?",
      date: "dddd D MMM YYYY",
      timezone: "Veuillez choisir le fuseau horaire dans la configuration",
      perfcellId: "Id Perfcell",
      inspect1: "SVP, prendre le prochain masque et l’identifier avec le carton mauve",
      inspect2: "comme échantillon de test de laboratoire.",
      inspect3: "Assurez-vous d’écrire la cellule, date et # d’échantillon.",
    },
    reports: {
      searchID: "Entrez l'ID de la commande",
      byO_journey: "Pour tout le parcours de la commande",
      singgle_order_journey: "Parcours d’une commande unique",
      waitingNotice: "Pour une période de plus de 2 jours, le rapport pourrait prendre quelques minutes à être généré.",
      byOID_XLSX: "Par ID de commande EXCEL",
      byOID_PDF: "Par ID de commande PDF",
      byOID: "Par ID de commande",
      title: "Rapports",
      uncompleteOrder: " Commandes Incomplètes Seulement",
      cancel: "Retour",
      submit: "Générer",
      from: "DE",
      to: "À",
      orderId: "ID",
      daily: "Rapport journalier",
      xlsDaily: "Excel journalier Standard",
      pdfDailyStd: "PDF journalier Standard",
      pdfDailyOEE: "PDF journalier OEE",
      xlsDailyOEE: "Excel journalier OEE",
      comment2: "Rapport de lignes régulières et cellules.",
      comment3: "Rapport pour la Calandre.",
      downtime: "Rapport des Arrêts",
      emplist: "Liste des Employés",
      emp: "Rapport des Employés",
      empDaily: "CSV journalier",
      empDetail: "PDF détaillé",
      products: "Rapport des Produits (standards)",
      eff: "Efficacité",
      prod: "Production",
      quality: "Performance Qualité",
      review: "Révision",
      qemp: "Rapport Employé",
      fromWeek: "DE SEMAINE",
      toWeek: "À SEMAINE",
      qEmps: "EMPLOYÉ(S)",
      ship: "Rapport d'Expédition",
      shipQC: "Utiliser le rapport QC",
      std: "Rapport des Standards",
      sys: "Rapport du Système",
      before: "Date de fin doit être plus grande ou égale à Date de début",
      nbDays: " jours maximum",
      linePHolder: "Choisir une Ligne (s)",
      schedPHolder: "Choisir une cédule (s)",
      stdCatPHolder: "Choisir une catégorie (s) de standard",
      stdPHolder: "Choisir un standard (s)",
      empPHolder: "Choisir un employé (s)",
      weekPHolder: "Choisir la semaine",
      errQC: "Erreur de lecture du rapport QC",
      toBigQC: "Le rapport QC est trop volumineux. Maximum 1 GB.",
      noLine: "Sélectionner au moins une ligne.",
      weekDate: "D MMM",
      week: "Semaine ",
      fromWFD: ", du ",
      toWLD: " au ",
      splitLines: "Diviser les lignes.",
      withInactive: "Inclure les inactifs",
      addPageJump0: "Pas de saut de page",
      addPageJump1: "Ajouter des sauts de page aux Lignes",
      addPageJump2: "Ajouter des sauts de page aux Lignes et aux Cédules",
      newFileQC: "Télécharger un nouveau rapport QC.",
    },
    factory: {
      title: "Détails de la Compagnie",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      name: "Nom:",
      namePHolder: "Entrer le nom",
      logo: "Fichier Logo (.png seulement):",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      png: "Le fichier logo doit être un .png!",
      success: "Enregistré avec succès",
      version: "Version",
      psw: "Mot de passe de l'Administrateur",
      adminTimeout: "Délai d'expiration du mot de passe Administrateur ",
      inSeconds: "(en secondes)",
      timeZone: "Fuseau horaire",
      tzPHolder: "Veuillez choisir le fuseau horaire dans la configuration",
      pswLength: "Le mot de passe doit contenir 5 caractères minimum",
      timeZoneErr: "Vous devez choisir un fuseau horaire",
      dateFormat: "Format pour les dates",
    },
    factorySetup: {
      errorMsg: "L’image doit avoir un maximum de 1.0MB",
      browse: "Feuilleter",
      title: "Réglages & Assurance Qualité",
      setup: "Réglages",
      startDay: "Tampon de départ de la journée ",
      decimals: "Décimales sur l'Actuel",
      minEarned: "Minutes gagnées sur les changement du standard",
      inMinutes15: "(en minutes, maximum 90 minutes)",
      inMinutes90: "(en minutes, maximum 90 minutes)",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Enregistré avec succès",
      breaksInc: "Inclure les temps de pauses dans les minutes travaillées",
      scanned: "Autoriser le même numéro WO numérisé sur différentes lignes",
      needTask: "Les employés doivent choisir une tâche dans les opérations",
      custom: "Boutons de minutes gagnées personnalisés",
      max6: "Maximum 6 boutons personnalisé",
      new: "Ajouter un nouveau bouton",
      delete: "Supprimer ce bouton",
      confirmDelete: "Êtes vous certain de vouloir complètement supprimer ce bouton de minutes gagnées personnalisé",
      desc: "Description",
      minutes: "Minutes",
      icon: "Icône(PNG seulement)",
      quality: "Assurance Qualité",
      inspector: "Inspecteur",
      inspPHolder: "Sélectionner l'inspecteur...",
      lines: "Ligne(s)",
      linesPHolder: "Sélectionner les ligne(s) à inspecter...",
      sched: "Cédule(s)",
      schedPHolder: "Sélectionner les cédule(s) pour l'inspection...",
      fixedQuality: " Fixe :",
      afterEvery: " Après chaque",
      nbFixedQuality: " unité produite",
      randomQuality: " Aléatoire :",
      nbRandomQuality: " inspections par cédule",
      msgInspectorQuality: " Message pour l'inspecteur",
      msgTabletQuality: " Message pour la tablette",
      zeroNbFixed: "Il manque le nombre d'unité produite.",
      zeroNbRandom: "Il manque le nombre d'inspections par cédule.",
      noDestination: "Sélectionner au moins une destination des messages.",
      noInspector: "Il manque l'inspecteur.",
      noLine: "Il manque les lignes.",
      noSchedule: "Il manque les cédules.",
      maxDecimals: "Le nombre maximum de decimales est 2",
      maxBuffer: "Le maximum du tampon de départ de la journée est 30 minutes",
      moveToLine: "Option de déplacement des employés d’une ligne à l’autre dans le module Opération",
    },
    incentive: {
      title: "Motivation",
      smallInPercent: " (en pourcentage)",
      smallHourlyRate: " (Taux Horaire)",
      hourlyRate: "Taux Horaire ",
      minimumPercent: "Minimum pour le Bonus ",
      baseBonus: "Bonus de base suite à l'atteinte de l'efficacité minimale ",
      maximumBonus: "Bonus Maximum ",
      efficiencyNoTime: "Efficacité pour les produits sans temps standard ",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Sauvegarde réussie",
    },
    configScList: {
      title: "Cédules",
      search: "Recherche dans les Descriptions",
      desc: "Description",
      start: "Départ",
      end: "Arrêt",
      edit: "Voir/Éditer",
      remove: "Enlever",
      confirm: "Supprimer cette Cédule ?",
      previous: "Page précédente",
      next: "Page suivante",
      cancel: "Retour",
      addNew: "Ajouter une nouvelle Cédule",
    },
    configSched: {
      title: "Détail de la Cédule",
      desc: "Description",
      descPHolder: "Entrer la description de la cédule",
      weekdays: "Jours de la semaine pour démarrer la cédule",
      info: "Le choix des journées sont ",
      green: "Vert",
      start: "Départ",
      end: "Fin",
      lunch: "Heure du repas",
      break1: "Heure de pause 1",
      break2: "Heure de pause 2",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      descLength: "La Description doit contenir 3 caractères minimal de long",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
      active: "Actif",
      noStartTime: "Départ est manquant",
      noEndTime: "Fin manquant",
      noWeekDay: "Veuillez choisir minimalement une journée",
      missLunch: "Veuillez déterminer la durée du repas avant d'ajouter la durée",
      missBreak: "Veuillez déterminer la durée des pauses avant d'ajouter la durée",
      badTimes: "Les repas et les pauses ne doivent pas se chevaucher ni s'étendre au-delà de l'horaire",
      overlap: "Cette cédule chevauche 2 journées. Indiquer les données dans les rapports:",
      startDay: "La date du départ de la cédule",
      endDay: "La date de la fin de la cédule",
      split: "Les 2 jours",
      realStart: "En utilisant le temps réel de départ de la session",
      configStart: "En utilisant le temps de départ configuré dans la cédule",
      realEnd: "En utilisant le temps réel de fin de la session",
      configEnd: "En utilisant le temps de fin configuré dans la cédule",
      autoLoggin: "Pour activer la fonction de connexion automatique des employés, sélectionnez l'une des options suivantes:",
      beginShift: "les employés enregistrés au début du quart de travail seront enregistrés le jour suivant prévu dans la cédule",
      endShift: "les employés connectés à la fin du quart de travail seront enregistrés le jour suivant prévu dans la cédule",
      lunchLength: "Durée du temps de repas ",
      inMinutes90: "(en minutes, maximum 90 minutes)",
      maxLunch: "Le temps maximun des lunchs est 90 minutes",
      break: "Pause",
      maxBreak: "Le temps maximun des breaks est 90 minutes",
      breakLength: "Durée de la pause",
    },
    configLinesList: {
      title: "Lignes, Cellules, Machines",
      search: "Recherche avec le Nom ou la Description",
      activeInactive: "Actif/inactif",
      name: "Nom",
      desc: "Description",
      edit: "Voir/Éditer",
      remove: "Retirer",
      clone: "Dupliquer",
      confirm: "Retirer cette ligne ?",
      previous: "Page précédente",
      next: "Page suivante",
      cancel: "Retour",
      addNew: "Ajouter une nouvelle Ligne",
    },
    configLine: {
      multiBox: "Multi-Box",
      title: "Détail de ligne",
      name: "Nom de la ligne",
      namePHolder: "Entrer le nom de la ligne",
      selectSchedule: "Choisir la (les) cédule (s)",
      desc: "Description de la ligne",
      descPHolder: "Entrer la description de la ligne",
      supportLine2: "Ligne de support 2",
      associateLines2: "Si 'Ligne de support 2' est coché, l'efficacité de cette ligne et des opérateurs sera basé sur celle de ces lignes:",
      lines2PHolder: "Choisir les lignes de support",
      extScanner: "Cette ligne fonctionne seulement avec un scanner.",
      scannerLines: "Les scans provenant de ces lignes seront inscrit sur cette ligne.",
      scannerPHolder: "Choisir les lignes",
      batch: "Quantité en lot",
      supApproval: "Besoin de l'approbation du superviseur pour fermer cette ligne avant la fin de la cédule",
      inspectLine: "Ceci est une Ligne d’Inspection",
      tabNoEff: "Pas d’affichage de l’efficacité dans le module Opération",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      nameLength: "Le nom devrait contenir 2 caractères minimalement",
      lineExist: "Ce nom de ligne existe déjà",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
      needTasks: "Les employés doivent choisir une tâche dans les opérations",
      notAllSched: "Seulement la cédule courante sera considérée pour calculer l’efficacité",
      allSched: "Inclure toutes les cédules dans le calcul de l'efficacité",
      highEff: "Quand l’efficacité ne doit pas changer selon le # d’opérateurs: Automation, Lignes Héritage, Calandre",
      cOverBegin: "Notification au démarrage de la ligne : 'Etes-vous en démarrage ou en production'",
      assignTasks: "Associer toutes les tâches à cette ligne?",
      oui: "Oui",
      non: "Non",
      askConfirm: "Êtes-vous sûr de confirmer ces modifications",
      selectCleaningFactor: "Sélectionnez un facteur de nettoyage",
      minutes_added_standard_change: "Minutes accordées à l'équipe (ajouté aux minutes gagnées) pour chaque changement de standard",
    },
    configTasksList: {
      title: "Gestion des Tâches",
      search: "Recherche avec le Nom ou la Description",
      name: "Nom",
      desc: "Description",
      edit: "Voir/Éditer",
      remove: "Retirer",
      clone: "Dupliquer",
      confirm: "Retirer cette tâche ?",
      previous: "Page précédente",
      next: "Page suivante",
      cancel: "Retour",
      addNew: "Ajouter une nouvelle Tâche",
    },
    configTask: {
      title: "Détail de tâche",
      name: "Nom de la tâche",
      namePHolder: "Entrer le nom de la tâche",
      desc: "Description de la tâche",
      descPHolder: "Entrer la description de la tâche",
      lines: "Cette tâche peut être utilisée sur ces lignes:",
      isSupport1: "Cette option peut être utilisée pour:",
      isSupport2: "1- Les employés de support (superviseurs, replenisher) qui servent plus d'une ligne",
      isSupport3: "2- Les tâches qui sont partagées entre plusieurs lignes",
      isSupport4: "Dans les deux cas, on pert l'exactitude de l'efficacité mais on gagne le suivi de la qualité",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      nameLength: "Le nom devrait contenir 3 caractères minimalement",
      lineExist: "Ce nom de ligne existe déjà",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
    },
    configAndonsList: {
      title: "Gestion des Andons",
      search: "Recherche avec le Id ou la Description",
      nodeId: "Node ID",
      desc: "Description",
      edit: "Voir/Éditer",
      remove: "Retirer",
      confirm: "Retirer ce Andon ?",
      previous: "Page précédente",
      next: "Page suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouvel Andon",
    },
    configAndon: {
      title: "Détail du Andon",
      nodeId: "Node ID du Andon",
      nodeIdPHolder: "Entrer le Node ID du Andon",
      desc: "Description du Andon",
      descPHolder: "Entrer la description du Andon",
      associateLines: "Lignes associées",
      linesPHolder: "Choisir les lignes",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      andonExist: "Le ID du Andon doit être unique",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
    },
    tabLine: {
      noLine: "Étape 1 : Cliquer pour choisir une Ligne",
      emptySched: "Cédule",
      noSched: "Cliquer pour choisir une Cédule",
      schedLock: "Vous ne pouvez pas changer la cédule durant la production. Veuillez fermer et repartir la session",
    },
    tabStd: {
      emptyStd: "Numéro de Produit/Tâche",
      noStd: "Étape 2 : Cliquer pour sélectionner le numéro de Produit/Tâche",
      SAM: "SAM ",
      SCT: "SCT ",
      NAT: "NAT : ",
      Takt: "Takt Time : ",
      CD: "CD : ",
      units: "Unités",
    },
    tabTeam: {
      btn_remove: "Déplacer ou enlever cet employé",
      btn_edit: "Modifier la tâche de l’employé",
      emptyTeam: "Membres de l'Équipe",
      noTeam: "Étape 3 : Cliquer pour choisir les Membres de l'Équipe",
      empsWorking: "Ces employés sont déjà sur une autre ligne et ont été supprimés de cette ligne : ",
      empWorking: "Cet employé est déjà sur une autre ligne et a été supprimé de cette ligne : ",
      add: "MODIFIER ",
      actual: "Actuellement : ",
      members: "Membre dans l'Équipe | Membres dans l'Équipe",
      remove: "enlever",
      cancel: "garder",
      last: "Vous ne pouvez pas enlever le dernier employé sur la ligne.",
      editTeam: "modifier l'équipe",
      moveToLine: "Voulez-vous enlever cette personne définitivement ou déplacer sur une autre ligne?",
      moveIt: "déplacer",
      deleteIt: "enlever",
    },
    tabControl: {
      minutes: " minutes ajoutées",
      title4: "Temps d’arrêts",
      comment40: "Machine brisée",
      comment41: "Pause et lunch",
      comment42: "Approbation échantillon",
      comment43: "Réunion",
      title5: "Mise en course",
      comment50: "Setup de début du quart",
      comment51: "Changer de produit ou d'emballage",
      comment52: "Nettoyage",
      prepOrProd1: "Bienvenue à la ",
      prepOrProd2: ", êtes-vous en train de vous organiser (apporter les ingédients, sacs, préparer la machine) ou prêt à produire?",
      preparing: "Organiser",
      produce: "Produire",
    },
    selectLine: {
      noLine: "Il n'ya pas eu de configuration de Ligne",
      sLine: "Choisir une Ligne",
      name: "Nom",
      desc: "Description",
      cancel: "Annuler",
      save: "Sauvegarder",
    },
    selectSendToLine: {
      noLine: "Aucune autre ligne en opération",
      selectTask: "Sélectionner une tâche",
    },
    selectSched: {
      noSchedule: "Il n'y a pas de configuration de Cédule",
      sSchedule: "Cédule",
      desc: "Description",
      cancel: "Annuler",
      save: "Sauvegarder",
    },
    selectStd: {
      noStandard: "Il n'y a pas de Standard de configuré",
      noCat: "Il n'y a pas de Categorie de configuré",
      allCatStds: "TOUTES LES NORMES",
      catName: "Nom des catégories",
      selectCat: "Choisir une catégorie",
      sStandard: "Choisir un Standard",
      stdPart: "Numéro de Produit/Tâche",
      stdDesc: "Description",
      category: "Catégorie",
      searchPHolder: "Texte de recherche",
      clear: "Effacer",
      useTakt: "Utiliser un Takt Time",
      valueNAT: "Temps Net Disponible (NAT) : ",
      valueCD: "Demande Client (CD) : ",
      changeCat: "Changer de Catégorie",
      cancel: "Annuler",
      save: "Sauvegarder",
      courierProcessType: "Type de processus de transporteur:",
      courierId: "ID de transporteur:",
      routeNumber: "Numéro d'itinéraire:",
      confirm: "Confirmer",
      isCourierProcess: "Le processus de transporteur est-il en cours?:",
      inboundDescription: "Le programme prend l'*identifiant du courrier* de la première barre de code et valide que les colis suivants appartiennent au même courrier",
      outboundDescription: "Le programme prend le *numéro de route* de la première barre de code et valide que les colis suivants appartiennent à la même route",
      inboundTitle: "Validation du processus entrant par courrier",
      outboundTitle: "Validation du processus de transporteur sortant",
      courierProcessTypeSelect: "Type de processus de transporteur:",
      yes: "Oui",
      no: "Non",
    },
    selectDown: {
      noDown1: "Il n'y a pas de Temps d'Arrêts pour cette ligne.",
      noDown2: "Aller à la configuration des Temps d'Arrêts pour ajouter cette ligne.",
      sDown: "Sélectionner une raison de Temps d'Arrêt",
      texte: "Description",
      password: "Mot de passe",
      enterPsw: "Le superviseur doit saisir le Mot de passe",
      wrongPsw: "Mauvais Mot de passe",
      cancel: "Annuler",
      save: "Débuter l'Arrêt Complet",
    },
    selectChangeover: {
      noChangeover1: "Il n'y a pas de Mise en course pour cette ligne.",
      noChangeover2: "Aller à la configuration des Mise en course pour ajouter cette ligne.",
      sChangeover: "Sélectionner une raison de Mise en course",
      texte: "Description",
      password: "Mot de passe",
      enterPsw: "Le superviseur doit saisir le Mot de passe",
      wrongPsw: "Mauvais Mot de passe",
      cancel: "Annuler",
      save: "Débuter le Mise en course",
    },
    selectSms: {
      fromList: "Choisir le texte du Message",
      typeText: "Ou entrer un texte",
      noRecip: "Il n'y a pas de récipient de configuré",
      sRecip: "Sélectionner un récipient",
      noMsg: "Il n'y a pas de message de configuré",
      sMsg: "Sélectionner un message",
      inputMsg: "Texte du message",
      enterText: "Entrer le texte",
      cancel: "Annuler",
      save: "Envoyer",
    },
    selectDefect: {
      noDefect: "Il n'y a pas de défaut de configuré",
      sDefect: "Sélectionner un défaut",
      inputComment: "Commentaire",
      enterText: "Entrer le texte",
      cancel: "Annuler",
      save: "Sauvegarder",
    },
    selectHelp: {
      machine: "Machine Brisée",
      besoin: "Besoin de fournitures ou de matières premières",
      question: "Question au superviseur ou à l'assurance qualité",
      red: "Éteindre le Andon rouge",
      orange: "Éteindre le Andon jaune",
      green: "Éteindre le Andon vert",
      message: "Voulez-vous envoyer un message texte?",
      total: "Est-ce que votre ligne est complètement arrêtée?",
      oui: "Oui",
      non: "Non",
      cancel: "Annuler",
      save: "Sélectionner",
    },
    endProd: {
      confirmOut1: "You are ",
      confirmOut2: " minutes avant la fin de la Cédule",
      confirmOut3: "Vous avez besoin de l'approbation du superviseur pour mettre fin aux opérations",
      password: " Mot de passe",
      enterPsw: "Le superviseur doit saisir le Mot de passe",
      wrongPsw: "Mauvais Mot de passe",
      cancel: "Annuler",
      save: "Fin de production",
    },
    selectTeam: {
      noEmployee: "Il n'y a pas de configuration d'employés",
      title: "Sélectionner les Employés présents dans votre Équipe",
      newTeam: "Employés présents dans votre Équipe : ",
      addEmp: "Ces employés seront ajoutés : ",
      removeEmp: "Ces employés seront retirés : ",
      firstName: "Prénom",
      lastName: "Nom de famille",
      alias: "# Employé",
      task: "Tâche",
      selectTask: "Sélectionner une tâche pour ",
      taskPHolder: "Sélectionner une tâche",
      onLine: "Ligne",
      search: "Recherche",
      next: "suivant",
      notFound: "Texte inexistant",
      clearList: "Retirer tout",
      cancel: "Annuler",
      save: "Sauvegarder",
      enterTask: "- TÂCHE MANQUANTE -",
      grey: "Cet employé est déjà sur une autre ligne",
    },
    standardReport: {
      entry: "Standard",
      status: "État",
      fermer: "fermer",
      updated: "Mis à jour",
      created: "Créé",
      categoryError: "Erreur : la catégorie n'existe pas",
    },
    configStdsList: {
      title: "Standards",
      search: "Recherche par Numéro de Produit/Tâches ou Description",
      part: "Numéro de Produit",
      desc: "Description",
      sam: "Temp standard",
      edit: "Voir/Éditer",
      remove: "Supprimer",
      confirm: "Supprimer ce Standard ?",
      previous: "Page Précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouveau Standard",
      confirmStatus: "Êtes-vous sûr de vouloir apporter des modifications à la gestion standard",
      import: "Importer csv",
    },
    configStd: {
      linesHeading: "Cette norme peut être utilisée sur ces lignes:",
      linesPH: "Sélectionner des ligness",
      title: "Détail du Standard",
      partNumber: "Numéro de Produit/Tâche",
      partPHolder: "Saisir le Numéro de Produit/Tâche",
      desc: "Description du Standard",
      descPHolder: "Saisir la description du Standard",
      SAM: "Temp standard : Temps alloué pour fabriquer une unité à 100% d’efficacité",
      effNoTime: "Utiliser une Efficacité pré-établie",
      hour: "Heures",
      min: "Minutes",
      sec: "Secondes",
      category: "Catégorie du Standard",
      catPHolder: "Saisir la Catégorie du Standard",
      toProduce: "Quantité à produire (0 pour une quantité illimitée)",
      noChangesAllowed: "La quantité ne peut être stockée et enregistrée qu'une seule fois, aucune modification n'est autorisée",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      partLength: "Le Numéro de Produit/Tâche doit contenir 3 caractères minimum",
      missingCat: "La Catégorie est obligatoire",
      bigSAM: "Les MAS doivent être plus petit que 24 heures",
      smallSAM: "Les MAS doivent être au moins .1 seconde",
      partExist: "Le Numéro de Produit/Tâche existe déjà",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
    },
    configUsersList: {
      title: "Employés",
      search: "Recherche par Prénom, Nom de famille ou # Employé",
      firstName: "Prénom",
      lastName: "Nom de famille",
      alias: "# Employé",
      edit: "Voir/Éditer",
      remove: "Supprimer",
      confirm: "Supprimer cet Employé ?",
      previous: "Page Précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouvel Employé",
      importUsers: "Importer csv",
      updated: "Employé mis à jour",
      created: "Employé créé",
      number: "erreur: mauvais nombre de champs",
      empty: "erreur: un ou plusieurs champs vide",
      server: "erreur serveur",
    },
    usersReport: {
      entry: "entrée du fichier csv",
      status: "État",
      fermer: "fermer",
    },
    configUser: {
      title: "Information de l'Employé",
      first: "Prénom",
      firstPHolder: "Saisir le prénom",
      last: "Nom de famille",
      lastPHolder: "Saisir le nom de famille",
      alias: "# employé",
      aliasPHolder: "Saisir le numéro employé",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      userExist: "Le numéro employé doit être unique",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
    },
    configCategoriesList: {
      title: "Catégories de Standard",
      search: "Recherche par Catégorie",
      name: "Nom",
      prodType: "Type",
      type0: "Régulier",
      type1: "Rejets",
      type1GF: "Reprises",
      type2: "Rejets 2",
      type3: "Autres",
      edit: "Voir/Éditer",
      remove: "Retirer",
      confirm: "Supprimer cette Catégorie ?",
      previous: "Page Précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter une nouvelle Catégorie",
    },
    configCategory: {
      title: "Détail de la Catégorie",
      name: "Nom",
      namePHolder: "Saisir le nom de la Catégorie",
      normal: "Régulier",
      rejectGF: "Reprises inclus dans la Production",
      rejectOther: "Rejets inclus dans la Production",
      //reject2: "Rejets non inclus dans la Production",
      other: "Autres (Essais, Échantillons...)",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      categoryExist: "Cette Catégorie existe déjà",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
      relatedDefects: "Choose rework (defects) related to this category",
      defectsPHolder: "Choose all defects",
    },
    configDownList: {
      title: "Arrêts de Production",
      search: "Recherche par Arrêt",
      text: "Texte",
      edit: "Voir/Éditer",
      remove: "Retirer",
      confirm: "Supprimer cet Arrêt ?",
      previous: "Page précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouvel Arrêt",
    },
    configDown: {
      title: "Détail de l'Arrêt",
      text: "Raison de l'Arrêt",
      textPHolder: "Saisir la raison de l'Arrêt",
      lines: "Lignes associées à l'Arrêt",
      linesPHolder: "Saisir les Lignes associés à l'Arrêt",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
      password: "Mot de passe",
      autoMsg: "Lancer le système de  messagerie",
    },
    configChangeoverList: {
      title: "Gestion des Mise en Course",
      search: "Recherche une Mise en Course",
      text: "Texte",
      edit: "Voir/Éditer",
      remove: "Retirer",
      confirm: "Supprimer ce Mise en course ?",
      previous: "Page précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouveau Mise en course",
    },
    configChangeover: {
      title: "Détail du Mise en course",
      text: "Raison du Mise en course",
      textPHolder: "Saisir la raison du Mise en course",
      lines: "Lignes associées au Mise en course",
      linesPHolder: "Saisir les Lignes associés au Mise en course",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
      password: "Mot de passe",
    },
    configRecipList: {
      title: "Gestion des Récipients des Messages",
      search: "Recherche par Nom ou Téléphone",
      name: "Nom",
      tel: "Téléphone",
      edit: "Voir/Éditer",
      remove: "Retirer",
      confirm: "Supprimer ce Récipient ?",
      previous: "Page précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouveau Récipient",
    },
    configRecip: {
      title: "Détail du Récipient",
      name: "Nom",
      namePHolder: "Saisir le Nom du Récipient",
      phone: "Téléphone",
      phonePHolder: "Saisir le Téléphone du Récipient",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      validPhone: "Le Numéro de Téléphone n'est pas valide",
      success: "Changements sauvegardés",
    },
    configMsgList: {
      title: "Gestion des Textes des Messages",
      search: "Recherche dans les textes",
      text: "Texte",
      edit: "Voir/Éditer",
      remove: "Retirer",
      confirm: "Supprimer ce Message ?",
      previous: "Page précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouveau Message",
    },
    configMsg: {
      title: "Détail du Message",
      text: "Message",
      textPHolder: "Saisir le texte du Message",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
    },
    configDefectsList: {
      title: "Gestion des Défauts",
      search: "Recherche dans les textes",
      text: "Texte",
      edit: "Voir/Éditer",
      remove: "Retirer",
      confirm: "Supprimer ce Défaut ?",
      previous: "Page précédente",
      next: "Page Suivante",
      cancel: "Retour",
      addNew: "Ajouter un nouveau Défaut",
    },
    configDefect: {
      title: "Détail du Défaut",
      text: "Défaut",
      textPHolder: "Saisir le texte du Défaut",
      active: "Actif",
      cancel: "Retour",
      submit: "Sauvegarder les changements",
      error: "Veuillez corriger l'erreur suivante: | Veuillez corriger les erreurs suivantes:",
      success: "Changements sauvegardés",
    },
    tabMain: {
      niceDay: "Bonne journée!",
      thankYou: "Merci",
      notSetUp: "Opération",
      startProd: "Cliquer pour débuter la production",
      outsideSchedule: "La Cédule choisie est terminée!",
      empsWorking: "Ces employés sont déjà sur une autre ligne et ont été supprimés de cette ligne : ",
      empWorking: "Cet employé est déjà sur une autre ligne et a été supprimé de cette ligne : ",
      tooEarly: "La cédule n'a pas encore débutée",
      notInLunch: "Vous ne pouvez pas ajouter des unités durant la pause du repas",
      notInBreak: "Vous ne pouvez pas ajouter des unités durant la pause",
      scannerOn: "POUR AJOUTER UNE UNITÉ MANUELLEMENT, LE BOUTON DU SCANNER DOIT ÊTRE VERT FONCÉ. APPUYEZ SUR LE BOUTON SCANNER POUR MODIFIER SA COULEUR.",
      scanned: "Déjà scanné",
      picker: {
        title: "Informations sur le sélecteur",
        body: "Cette commande a été prise par",
      },
      notPicked: {
        title: "Avertissement",
        body: "Cette commande n'a pas été scannée au picking",
      },
      scanOk: "Bon scan",
      notSaved: "Le scan n'as pas été sauvegardé!",
      actual: "Unités Produites Actuel",
      goal: "Unités Produites Objectif",
      actualAvrg: "Moyenne Unités Par Heure Actuel",
      goalAvrg: "Unités Par Heure Objectif",
      eff: "Efficacité",
      last: "Dernière entrée :",
      lastScanned: "Dernier scanné :",
      produce: "À produire",
      done: "complet",
      cycle: "Temps du Cycle",
      today: "Total du Jour",
      desc: "Description",
      style: "Style",
      comment6: "Pour le produit courant",
      comment7: "Pour le produit courant en temps réel",
      comment8: "Depuis le début de la cédule courante, sans considération des temps d’arrêts ou des mises en course",
      comment9: "Basé sur le standard du produit en cours",
      notValidInbound: "Cette case pour le transporteur **ORDER_COURIER** n'appartient pas au transporteur **LINE_COURIER** actuellement sortant",
      // "Cette commande n'appartient pas au même TRANSPORTEUR. Transporteur de commande (**ORDER_COURIER**), TRANSPORTEUR de ligne (**LINE_COURIER**)",
      notValidOutbound: "Cette case pour la route **ORDER_ROUTE** n'appartient pas à la route **LINE_ROUTE** actuellement sortante",
      // "Cette commande n'appartient pas au même ITINÉRAIRE. Itinéraire de commande (**ORDER_COURIER**), itinéraire de ligne (**LINE_COURIER**)",
      validationError: "Erreur de validation",
      errorInvalidation: "Erreur de validation, réessayez",
      orderScanned: "Commandes scannées",
      orderScannedTooltip: "Décompte des commandes",
      breakStart: "Début",
      breakEnd: "Fin",
      breakDuration: "Durée",
      breakRemaining: "Temps restant",
      breakTitle: "Temps de Pause",
      endBreak: "Finir la pause maintenant",
      loading: "Chargement en cours",
    },
    incorrectCourierValidation: {
      acceptAndChange: "Accepter et changer pour **MATCHID** **MATCHIDKEY**",
      aceptBox: "Accepter cette case",
      cancel: "Annuler",
    },
    keyPad: {
      valid: "VALIDE",
      close: "FERMER",
    },
    startProd: {
      confirm: "Prêt à débuter la production?",
      lineName: "Ligne : ",
      schedDesc: "Cédule : ",
      lunchTime: "Repas : ",
      breakTime: "Pause : ",
      stdPart: "Standard : ",
      sam: "MAS : ",
      teamList: "Équipe : ",
      startTime: "Les opérations débuteront à ",
      timeLimit: " si le bouton Départ est cliqué avant ",
      startNow: " si le bouton Départ est cliqué maintenant",
      cancel: "Annuler",
      start: "Départ",
    },
    chartsbi: {
      title: "Graphiques d'Intelligence d'Entreprise",
      cancel: "Retour",
      d1: "Production Journalière",
      d2: "Utiliser ce graphique pour visualiser la progression :",
      d3: "• d'une nouvelle équipe",
      d4: "• d'une équipe fabriquant un nouveau produit",
      d5: "• de l’équipe suite à un changement de procédure",
      d6: "• basé sur le nombre d’opérateurs",
      q1: "Quantité Vs Efficacité",
      q2: " Utiliser ce graphique pour visualiser comment les quantités produites d'un même produit influence la productivité.",
      u1: "Unités par Heure",
      u2: "Utiliser ce graphique pour visualiser la constance dans l’équipe heure par heure durant la journée.",
    },
    biCharts: {
      title: "INTELLIGENCE D'ENTREPRISE",
      from: "DE",
      to: "À",
      date: "DATE",
      selectLine: "CHOISIR UNE LIGNE",
      linePHolder: "Choisir une Ligne",
      selectSched: "CHOISIR UNE CÉDULE",
      schedPHolder: "Choisir une cédule",
      selectStd: "CHOISIR les PART #",
      stdPHolder: "Choisir les standards",
      generate: "Générer",
      export: "Export",
      before: "La date de fin doit être plus grande ou égale à la date de début",
      nbDays: " jours maximum",
      noLine: "Choisir une ligne.",
      noSched: "Choisir une cédule.",
      noStd: "Choisir au moins un standard.",
      cancel: "Retour",
    },
    biChartsDP: {
      title: "Production journalière et Nombre d’employés",
      chartTitle: "Graphique de la Production journalière et Nombre d’employés",
      headerPlant: "Plant",
      headerLine: "Ligne",
      headerSched: "Cédule",
      headerDate: "Date",
      headerTeam: "Employés",
      headerNbTeam: "Av. team M.",
      headerRegular: "Produits",
      headerOther: "Autres",
      headerReject: "Rejets",
      exportPdf: "Imprimer PDF",
    },
    biChartsQVE: {
      title: "Quantité de la Commande versus Efficacité",
      chartTitle: "Graphique de la Quantité de la Commande versus Efficacité",
      headerDate: "Date",
      headerPart: "Numéro Pièce",
      headerDesc: "Description",
      headerQte: "Quantitée",
      headerEff: "Efficacité",
      headerSAM: "S.A.M",
    },
    biChartsUPH: {
      title: "Unités Produites par Heure",
      chartTitle: "Graphique des Unités Produites par Heure",
      headerDate: "Date",
      headerTeam: "Employés",
      headerFrom: "De",
      headerTo: "À",
      headerRegular: "Produits",
      headerOther: "Autres",
      headerReject: "Rejets",
    },
    dashboard: {
      title: "Tableau de Bord d'Efficacité & Qualité",
      currProd: "PRODUCTION COURANTE",
      dayProd: "PRODUCTION DE LA JOURNÉE",
      lineAndSchedule: "LIGNE ET CÉDULE",
      stats: "STATISTIQUES",
      head: [
        "Ligne",
        "Cédule",
        "Standard",
        "Opération",
        "Membre(s) de l'équipe",
        "Unités actuel",
        "Unités objectif",
        "Efficacité",
        "Unités moyenne par heure",
        "Unités objectif par heure",
        "Unités complétées",
        "Autres unités",
        "Unités rejetées rework",
        "Efficacité",
        "        Temps takt",
        "Temps Cycle moyen",
        "<div id='comment1'>Unités par heure moyen <span style='color:red'> &#128712;</span></div>",
        "Unités dernière heure",
        "Unités par heure cédule précédente",
        "Tendance dernière vs moyenne",
      ],
      title1: "Unités par heure moyen",
      comment1: "Unités complétées à date dans le quart de travail divisées par le temps de travail depuis le début du quart de travail, excluant les temps d'arrêt et les pauses.",
      lineSelect: "Lignes affichées",
      linesPHolder: "Choisir les lignes",
      mmGoal: "Objectif Homme-Minute",
      mmGoalPHolder: "Choisir l'Objectif Homme-Minute",
      close: "Fermer",
      inDownTime: "Arrêt Complet de la Ligne",
      inChangeover: "Mise en Course",
      fullText: "Afficher la description complète des lignes",
    },
    dashboard2: {
      title: "Tableau de Bord des Présences",
      lineSelect: "Lignes affichées",
      linesPHolder: "Choisir les lignes",
      close: "Fermer",
    },
    downtime: {
      title: "ARRÊT",
    },
    changeover: {
      title: "MISE EN COURSE",
    },
    sendMsg: {
      title: "Envoyer des Messages aux Lignes ou aux Employés",
      line: "Envoyer un Message aux Lignes",
      linesPHolder: "Choisir les Lignes en opération",
      emp: "Envoyer un Message aux Employés",
      usersPHolder: "Choisir les Employés",
      all: "Envoyer un message à tous les équipements utilisant Perfcell",
      message: "Le texte du message",
      enterText: "Entrer le texte",
      toLines: "Message pour les Lignes : ",
      toUsers: "Message pour les employés : ",
      toAll: "Message pour Tous",
      cancel: "Annuler",
      send: "Envoyer",
    },
    confirmAutoLogin: {
      titre: "Confirmer que cet employé est sur cette ligne aujourd'hui: | Confirmer que ces employés sont sur cette ligne aujourd'hui:",
      confirm: "Confirmer",
    },
    followUps: {
      title: "Quality Performance Review",
      week: "Semaine ",
      from: " du ",
      to: " au ",
      fDate: "D MMM",
      maxPcent: "Objectif du maximum % d'erreur : ",
      employee: "Employé : ",
      empPHolder: "Selectionner un employé",
      supervisor: "Superviseur : ",
      suggest: "Nous vous suggérons de faire : ",
      action: "Que décidez-vous de faire? : ",
      actionChosed: "Action choisie : ",
      actionPHolder: "Sélectionner une action",
      comment: "Commentaires :",
      history: "Historique :",
      weekHist: "Semaine",
      maxHist: "Max %",
      empHist: "Employé %",
      suggestHist: "Action suggérée",
      actionHist: "Action choisie",
      commHist: "Commentaires",
      cancel: "Retour",
      saveMaxPcent: "Sauver Max %",
      saveEmployee: "Sauver cet Employé",
      needAction: "Vous devez sélectionner une action",
      needSupervisor: "Vous devez entrer un Superviseur",
      generate: "Générer le Rapport Employés Excel",
      savedOk: "Informations sauvegardées",
      scheds: "Noter les employés pour ces cédules :",
      schedsPHolder: "Sélectionner toutes les cédules :",
      sortByName: "Classer par nom",
      sortByPcent: "Classer par % d'erreur décroissant",
      askSaveEmp: "Voulez-vous sauvegarder les modifications à cet employé?",
    },
    cleaningFactorList: {
      title: "Facteur de nettoyage",
      search: "Rechercher par nom",
      table: {
        name: "Nom",
        edit: "Éditer",
        remove: "Retirer",
      },
      footer: {
        new: "Ajouter un nouveau facteur de nettoyage",
        cancel: "Retour",
        nextPage: "Page Suivante",
        previousPage: "Page Précédente",
      },
    },
    cleaningFactorDetail: {
      title: "Détail du facteur de nettoyage",
      form: {
        factorName: "Nom du facteur de nettoyage",
        factorNamePlaceholder: "Entrez le nom du facteur de nettoyage",
        itemName: "Nom de l'article",
        itemNamePlaceholder: "Entrez le nom de l'élément de facteur de nettoyage",
        linesLabel: "Ce facteur de nettoyage peut être utilisé sur ces lignes",
        linesPlaceholder: "Sélectionnez les lignes",
        cancel: "Retour",
        submit: "Sauvegarder les modifications",
        changesSaved: "Changements sauvegardés avec succès",
        cleaningFactorNameCannotBeEmpty: "Le nom du facteur de nettoyage ne peut pas être vide",
        cleaningFactorItemsCannotBeEmpty: "Le nom des éléments de facteur de nettoyage ne peut pas être vide",
      },
    },
    quantityProducedModal: {
      orderComplete: "La commande est complétée, voulez-vous quand-même ajouter cette quantité ?",
    },
  },
};

const i18n = new VueI18n({
  locale: getSavedLocale(),
  fallbackLocale: "fr",
  messages,
});

function getSavedLocale() {
  let locale = localStorage.getItem("locale");
  if (!locale) {
    localStorage.setItem("locale", "en");
    locale = "en";
  }
  return locale;
}

export default i18n;
